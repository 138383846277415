import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { FC, useEffect } from 'react'
import LazyLoad from 'react-lazy-load'

import styles from './Home.module.scss'
import { useUi } from '@/bus/ui'
import { AboutMain } from '@/sections/AboutMain'
import { Partners } from '@/sections/Partners'

const Program = dynamic(
  () => import('@/sections/Program').then(({ Program }) => Program),
  {
    ssr: false
  }
)

const Features = dynamic(
  () => import('@/sections/Features').then(({ Features }) => Features),
  {
    ssr: false
  }
)

const Methods = dynamic(
  () => import('@/sections/Methods').then(({ Methods }) => Methods),
  {
    ssr: false
  }
)

export const Home: FC = () => {
  const { t } = useTranslation('program')

  const { setSettings } = useUi()

  useEffect(() => {
    setSettings({ menu: true, footer: true })
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.home}>
      <div className={styles.home__screen}>
        <AboutMain className={styles.home__about} />
        <Partners />
      </div>

      <div id="opportunities">
        <LazyLoad className={styles.home__features}>
          <Features />
        </LazyLoad>
      </div>

      <div id="methods">
        <LazyLoad className={styles.home__methods}>
          <Methods />
        </LazyLoad>
      </div>

      <LazyLoad className={styles.home__program}>
        <Program
          heading={t('heading')}
          summary={t('summary')}
          table={t('table', { returnObjects: true })}
          notice={t('notice')}
        />
      </LazyLoad>
    </div>
  )
}
